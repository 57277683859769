.section-dashboard {
    padding-top: 50px;
    padding-bottom: 50px;
}

.dashboard-sidebar {

    &__user {
        text-align: center;
    }
}