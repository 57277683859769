.button {
    border: 2px solid transparent;
    cursor: pointer;
    font-size: 22px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    gap: 15px;
    padding: 15px 35px;
    text-align: center;
    transition: .5s;
}
.button-sm {
    font-size: 18px;
    padding: 13px 30px;
}
.button-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: #fff;
    
    &:hover, &:focus-visible {
        background-color: transparent;
        color: var(--color-primary);
    }
}
.button-secondary {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    color: #fff;
    
    &:hover, &:focus-visible {
        background-color: transparent;
        color: var(--color-secondary);
    }
}
.button-secondary-outline {
    background-color: transparent;
    border-color: var(--color-secondary);
    color: var(--color-secondary);
    
    &:hover, &:focus-visible {
        background-color: var(--color-secondary-dark);
        border-color: var(--color-secondary-dark);
        color: #fff;
    }
}
.button-secondary-transparent {
    background-color: transparent;
    border-color: transparent;
    color: var(--color-secondary);
    
    &:hover, &:focus-visible {
        color: var(--color-primary);
    }
}
.button-primary-3 {
    background-color: var(--color-primary-3);
    border-color: var(--color-primary-3);
    color: var(--color-secondary);
    
    &:hover, &:focus-visible {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        color: #fff;
    }
}
.button-white {
    background-color: var(--color-white);
    border-color: var(--color-white);
    color: var(--color-secondary);
    
    &:hover, &:focus-visible {
        background-color: transparent;
    }
}

.btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.btn-box__svg {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;

    svg {
        fill: var(--color-secondary) !important;
        transition: .5s;
    }

    &:hover {
        svg { fill: var(--color-primary) !important; }
    }
}