.checkbox-group {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;

    input { 
        display: none; 

        &:checked {
            & ~ .label {
                color: var(--color-primary);
                &::before {
                    background-color: var(--color-primary);
                    border-color: var(--color-primary);
                }
            }
        }
    }

    .label {
        display: inline-flex;
        align-items: flex-start;
        position: relative;
        transition: .5s;

        &::before {
            content: "\f1fa";
            font-family: uicons-regular-straight !important;
            font-style: normal;
            font-weight: normal !important;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: #fff;
            border: 2px solid var(--color-primary-3);
            color: #fff;
            display: inline-block;
            margin-right: 10px;
            padding: 2px;
            transition: .5s;
        }
    }  
}  

.notif {
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    margin-top: 4px;
    padding-left: 25px;
    position: relative;

    &::before, &::after {
        border-radius: 999rem;
        position: absolute;
        left: 9px;
        top: 13px;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    &::before { top: 11px; }

    &::after {
        -webkit-animation: growTransp 1s infinite backwards;
        animation: growTransp 1s infinite backwards;
    }

    &--success {
        color: var(--color-success);
        &::before, &::after { background-color: var(--color-success); }
    }

    &--danger {
        color: var(--color-danger);
        &::before, &::after { background-color: var(--color-danger); }
    }
}

.see-password-wrap {
    position: relative;

    .see-password {
        cursor: pointer;
        line-height: 1;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        &::before { 
            content: "\f2cf";
            display: inline-block;
            font-family: uicons-regular-straight !important;
            font-style: normal;
            font-weight: normal !important;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transform: translateY(0.09em);
        }
        &.active::before { 
            color: var(--color-primary); 
        }
    }
}

.input-1 {
    position: relative;
    width: 100%;
    height: 50px;
    background: #fff;
    border: 2px solid var(--color-primary-2);
    border-radius: 0;
    font-size: 15px;
    color: #808080;
    padding: 15px 20px;
    transition: all 500ms ease;
}