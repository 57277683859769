@media screen and (max-width: 1200px) {
    .page-header {
        &__right { display: none; }
    }
}

@media screen and (max-width: 991px) {

    .home-banner {
        &::before { display: none; }
        .container-top {
            grid-template-columns: 1fr;
            .right { display: none; }
        }
    } 
    
    .page-header {
        // height: 350px;
        &__title { 
            font-size: 40px; 
            &::before { margin-top: 25px; }
        }
    }

}


@media screen and (max-width: 768px) {

    .table-1 {
        display: block;

        th { display: none; }

        tbody,
        tbody > tr,
        tbody > tr > td {
            display: block;
            text-align: center; 
            width: 100% !important;
        }
    }    

    .table-documents {
        tbody > tr > td:nth-child(1) { 
            color: var(--color-secondary);
            font-weight: 600;
            padding-top: 15px;
            padding-bottom: 0; 
        }
        tbody > tr > td:nth-child(2) {
            padding-bottom: 0;
        }
        tbody > tr > td:last-child {
            border-bottom: 1px solid var(--color-primary-2);
            padding-bottom: 15px;
        }
    }
    
    .page-header {
        // height: 300px;
        &__content { top: 50%; }
        &__title { 
            font-size: 30px; 
            &::before { margin-top: 15px; }
        }
    }

    .p-md-25 { padding: 25px !important; }

}