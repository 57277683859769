.page-header {
    background-color: var(--color-primary-2);
    position: relative;
    isolation: isolate;
    height: 400px;
    z-index: 1;

    &::before {
        content: "";
        background-color: var(--color-primary-3);
        position: absolute;
        left: calc(45vw + 725px);
        top: 50%;
        width: 999vw;
        height: 100%;
        transform: translateY(-50%);
    }

    &::after {
        content: "";
        background: url(/storage/svg/arrow-down-octogone-1.svg) center center no-repeat;
        background-size: cover;
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 80px;
        width: 70px;
        transform: translate(-50%, 50%);
    }

    .container {
        position: relative;
        height: 100%;
    }

    &__content {
        position: absolute;
        top: 58%;
        left: 0;
        transform: translateY(-10%);
    }

    &__right {
        position: absolute;
        top: 0;
        right: calc(45vw - 800px);
        overflow: hidden;
        height: 100%;
        width: 800px;
        &::before {
            -webkit-mask-image: url('/storage/svg/octogone-mask-1.svg');
            mask-image: url('/storage/svg/octogone-mask-1.svg');
            -webkit-mask-mask-position: center;
            -webkit-mask-position: center;
            mask-position: center;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: auto;
            mask-size: auto;
            content: "";
            background: url('/storage/page-header-image-2.jpg') center center no-repeat;
            background-size: cover;
            position: absolute;
            top: 20px;
            right: 15px;
            height: 600px;
            width: 600px;
            z-index: 0;
        }
    }

    &__title {
        color: var(--color-secondary-dark);
        display: flex;
        align-items: flex-start;
        gap: 15px;
        font-size: 42px;
        line-height: 1.1;
        font-weight: bold;
        text-transform: uppercase;
        max-width: 950px;

        &::before {
            content: "";
            background-color: var(--color-primary);
            margin-top: 25px;
            height: 1px;
            width: 60px;
        }
    }

    .breadcrumb {
        background-color: transparent;
        font-weight: 600;
        padding: 0 10px;
        margin-bottom: 0;

        a {
            color: var(--color-primary);
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: "";
            -webkit-mask-image: url('/storage/svg/octogone-secondary.svg');
            mask-image: url('/storage/svg/octogone-secondary.svg');
            -webkit-mask-mask-position: center;
            -webkit-mask-position: center;
            mask-position: center;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: auto;
            mask-size: auto;
            background-color: var(--color-primary);
            height: 10px;
            width: 10px;
            margin-right: 5px;
        }
        .breadcrumb-item.active {
            color: var(--color-secondary-dark);
        }
    }
}