.image-1 {
    position: relative;

    &::after {
        content: "";
        border: 1px solid var(--color-primary);
        position: absolute;
        top: 15px;
        left: 15px;
        height: 100%;
        width: 100%;
    }
}