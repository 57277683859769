.section-title {
    isolation: isolate;
    position: relative;

    &::before {
        content: "";
        border: 1px solid var(--color-primary);
        opacity: .6;
        position: absolute;
        top: 50%;
        left: -10px;
        height: 50px;
        width: 50px;
        z-index: -1;
        transform: translateY(-50%) rotate(45deg);
    }

    &--center {
        text-align: center;
        &::before { 
            left: 50%; 
            top: 50%; 
            transform: translate(-50%,-50%) rotate(45deg); 
        }
    }

    &--sm {
        font-size: 25px;
    }

    &--white {
        color: #fff;
        &::before { border-color: #fff; }
    }
}