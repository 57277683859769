@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap');

@import './variables'; 
@import './functions'; 
@import './animations'; 
@import './typography'; 
@import './banner'; 
@import './buttons'; 
@import './card'; 
@import './dashboard'; 
@import './form'; 
@import './header'; 
@import './images'; 
@import './list'; 
@import './menu'; 
@import './miscs'; 
@import './page-header'; 
@import './table'; 
@import './responsive'; 
@import './helpers'; 