.bg-primary-2 { background-color: var(--color-primary-2) !important; }
.bg-primary-3 { background-color: var(--color-primary-3) !important; }
.bg-primary-4 { background-color: var(--color-primary-4) !important; }

.color-primary { color: var(--color-primary) !important }
.color-secondary { color: var(--color-secondary) !important }
.color-secondary-dark { color: var(--color-secondary-dark) !important }

.pad-top-200 { padding-top: 200px !important; }
.pad-top-75 { padding-top: 75px !important; }

.text-lg { font-size: 22px !important; }