.home-banner {
    background-color: var(--color-primary-2);
    padding-top: 215px;
    position: relative;
    isolation: isolate;
    z-index: 1;

    &::before {
        content: "";
        background-color: var(--color-primary-3);
        position: absolute;
        left: 65%;
        top: 50%;
        width: 999vw;
        height: 100%;
        transform: translateY(-50%);
    }

    .container-top {
        display: grid;
        grid-template-columns: 55% 45%;
        grid-gap: clamp-calc(992px, 1600px, 50px, 75px);
        position: relative;
        margin-bottom: 60px;
        z-index: 2;
    }

    &__img {
        -webkit-mask-image: url('/storage/svg/octogone-mask-1.svg');
        mask-image: url('/storage/svg/octogone-mask-1.svg');
        -webkit-mask-mask-position: center;
        mask-position: center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: auto;
        mask-size: auto;
        background: url('/storage/close-up-of-businessman-is-analyzing-data-in-office-w1098.webp') center center no-repeat;
        background-size: cover;
        width: 710px;
        height: 900px;
    }


    &__title {
        color: var(--color-secondary);
        font-size: clamp-calc(1200px, 1400px, 28px, 30px);
        font-weight: 500;
        margin-bottom: 35px;

        &__comp {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            gap: 10px;
            margin: 10px 0;
            position: relative;

            span {
                border: 1px solid var(--color-primary);
                color: var(--color-primary);
                font-size: 1.4em;
                font-weight: bold;
                display: inline-block;
                padding: 10px;
                text-transform: uppercase;

            //     &:first-child {
            //         position: relative;
            //     }
            //     &:first-child::before {
            //         content: "&";
            //         background-color: var(--color-primary-2);
            //         color: var(--color-secondary);
            //         font-size: 35px;
            //         font-weight: 600;
            //         line-height: 1;
            //         display: inline-block;
            //         padding: 0 10px;
            //         position: absolute;
            //         bottom: 0;
            //         left: 15px;
            //         transform: translateY(calc(50% + 5px));
            //     }
            }
        }

        &__end {
            overflow: hidden;
            display: block;
            padding-right: 15px;
            position: relative;
            isolation: isolate;

            &::before {
                content: "";
                background-color: var(--color-primary);
                position: absolute;
                top: 50%;
                left: 0;
                height: 1px;
                width: 999vw;
                z-index: -1;
            }

            & > span {
                background-color: var(--color-primary-2);
                display: inline-block;
                padding-right: 25px;
            }

        }
    }


    &__content {
        position: relative;
        isolation: isolate;
        margin-bottom: 40px;

        &::before {
            content: "";
            background-color: var(--color-primary-3);
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 999vw;
            z-index: -1;
        }

        &::after {
            content: "";
            border: 1px solid var(--color-primary);
            position: absolute;
            bottom: -15px;
            right: -15px;
            height: 100%;
            width: 999vw;
            z-index: -2;
        }

        &__wrap {
            background-color: var(--color-primary-3);
            padding: 50px 25px 25px 0;
            position: relative;

            &::before {
                content: "";
                background: url('/storage/svg/arrow-down-octogone-1.svg') center center no-repeat;
                background-size: cover;
                position: absolute;
                top: 0;
                left: 50%;
                height: 80px;
                width: 70px;
                transform: translate(-50%,-50%);
            }
        }
    }

    .container-bottom {
        margin-top: -100px;
        position: relative;
        transform: translateY(100px);
        z-index: 0;
    }
}