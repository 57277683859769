/**
 * ------------------------------------------------
 * CSS Variables
 * ------------------------------------------------
 */

:root {
    --color-primary: #009ADE;
    --color-primary-2: #DFEDF3;
    --color-primary-3: #CCE4EF;
    --color-primary-4: #f3f9fc;
    --color-secondary: #0055B8;
    --color-secondary-dark: #0C3D75;
    --color-dark: #000;
    --color-grey: #6b88aa;
    --color-white: #fff;
    --color-success: #2ecc71;
    --color-danger: #e74c3c;
    --color-body: var(--color-grey);
    --font-family-1st: 'Barlow', sans-serif; 
    --font-family-body: var(--font-family-1st); 
    --font-family-heading: var(--font-family-1st); 
}