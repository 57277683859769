h1, h2, h3, h4, h5, h6 {
    color: var(--color-secondary-dark);
}

.blockset {
    margin-bottom: 50px;
    &__legend {
        margin-bottom: 10px;
        position: relative;
        span {
            background-color: #fff;
            color: var(--color-secondary-dark);
            display: inline-block;
            font-size: 25px;
            font-weight: 600;
            padding-right: 25px;
        }

        &::before {
            content: "";
            background-color: var(--color-primary);
            position: absolute;
            top: 50%;
            left: 0;
            height: 2px;
            width: 100%;
            z-index: -1;
        }
    }
}

.clients-carousel {
    max-height: 150px; 

    .owl-nav { display: none; }
}

.dynacontent {

    * + h2,
    * + h3,
    * + h4 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    p, ul { margin-bottom: 15px; }

    a {  @extend .link; }

    ul { 
        @extend .list-links;
        
        li {  
            color: var(--color-secondary);
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;

            &::before {
                transform: translateY(5px) rotate(45deg);
            }
        }
    }

}

.icon-fix {
    display: inline-block;
    font-size: .95em;
    transform: translateY(2px);
}

.image_block_21 {
    margin: 0 auto;
    max-width: 570px;
}

.team-three.team-page-3 .team-block-one {
    .image-box {
        position: relative;
    
        &__inside {
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            padding: 15px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
            transition: .5s;

            & p {
                color: #fff !important;
                font-weight: bold !important;
                font-size: 18px;
                line-height: 1.3;
                margin-bottom: 20px;
            }
        }
    }

    .inner-box:hover {
        .image-box__inside {
            opacity: 1;
            visibility: visible;
        }
    }
}

.link-arrow {
    color: var(--color-body);
    font-weight: 600;
    display: inline;

    &::after {
        content: "\f14b";
        display: inline-block;
        margin-left: 10px;
        font-family: uicons-regular-straight !important;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translateY(3px);
    }

    &:hover, &:focus-visible {
        &::before { width: 80%; }
    }
}

.link-classic {
    color: var(--color-body);
    display: inline-flex;
    align-items: flex-end;
    font-weight: 600;
    gap: 10px;
    position: relative;
    text-decoration: underline;

    // &::before {
    //     content: "";
    //     background-color: var(--color-body);
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     height: 1px;
    //     width: 100%;
    //     transition: .5s;
    // }

    &:hover, &:focus-visible {
        &::before { width: 80%; }
    }
}

.link {
    color: var(--color-primary);
    display: inline-block;
    font-weight: 600;
    position: relative;

    &::before {
        content: "";
        background-color: var(--color-primary);
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 0;
        transition: .5s;
    }

    &:hover, &:focus-visible {
        text-decoration: none;
        &::before { background-color: var(--color-secondary); width: 100%; }
    }

    &-lg {
        font-size: 25px;
    }

    &-white {
        color: #fff;
        &::before { background-color: #fff; }
        &:hover, &:focus-visible {
            color: var(--color-primary);
            &::before { background-color: var(--color-primary); }
        }
    }

    &-danger {
        color: var(--color-danger);
        &::before { background-color: var(--color-danger); }
        &:hover, &:focus-visible {
            color: var(--color-danger);
            &::before { background-color: var(--color-danger); }
        }
    }
}

.login-card {
    margin: 0 auto;
    max-width: 500px;
}

@media screen and (max-width: 568px) {
    
}