.sidebar-menu {
    li a {
        background-color: var(--color-primary-4);
        color: var(--color-secondary-dark);
        display: block;
        font-size: 18px;
        font-weight: 600;
        padding: 15px 30px;
        transition: .5s;

        &:hover, &:focus-visible { background-color: var(--color-primary-3); }
        &.active { 
            background-color: var(--color-primary) !important;
            color: #fff !important;
        }

        i { color: var(--color-primary); }
    }
}