.list-checked {
    li {
        font-size: 20px;
        padding-left: 50px;
        position: relative;
        margin-bottom: 12px;

        &::before {
            content: "";
            background: url('/storage/svg/check-octogone-primary.svg') center center no-repeat;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            height: 33px;
            width: 29px;
        }
    }
}

.list-links {

    li {
        display: flex;
        gap: 5px 15px;
        margin-bottom: 20px;

        &::before {
            content: "";
            border: 2px solid var(--color-primary);
            flex-shrink: 0;
            margin-top: 6px;
            height: 10px;
            width: 10px;
            transform: rotate(45deg);
        }

        a {
            line-height: 1.2;
        }
    }

    &-white {
        a { 
            color: #fff; 
            &:hover { color: var(--color-primary); }
        }
    }
}