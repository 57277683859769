.table-1 {
    width: 100%;

    th {
        border-bottom: 1px solid var(--color-primary-2);
        color: var(--color-secondary);
        font-weight: 600;
        padding: 8px;
    }

    td {
        font-size: 18px;
        padding: 8px;
    }
}

.table-documents {
    tbody > tr > td:nth-child(1) { width: 60%; }
    tbody > tr > td:nth-child(2) { width: 20%; }
    tbody > tr > td:nth-child(3) { width: 20%; }
}